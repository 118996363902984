<template functional>
    <span v-if="props.bunch.option">
        {{ $options.methods.formatEmptyString(props.bunch.option.name, props.bunch.option.id) }}
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "BunchOutput",
    props: {
      bunch: {
        type: Object,
        default: null
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped>

</style>
